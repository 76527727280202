/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2023-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */
// external dependencies
import moment from "moment";
import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";

// internal dependencies
import { EventDTO } from "@/models/EventDTO";
import { EventStatisticsDTO } from "@/models/EventStatisticsDTO";
import { MetaView } from "@/views/MetaView";

@Component({
  computed: {
    ...mapGetters({
      address: "auth/getCurrentUserAddress",
      currentEvent: "app/getEvent",
      statistics: "statistics/getEventStatistics",
    }),
  },
})
export default class EventButton extends MetaView {
  public address!: string;
  public currentEvent!: EventDTO;
  public now: number = new Date().getTime();

  private statistics!: EventStatisticsDTO;

  public get eventStarted() {
    return this.currentEvent?.startDate <= this.now;
  }

  public get eventEnded() {
    return this.currentEvent?.endDate <= this.now;
  }

  public get countDown() {
    const timeleft =
      this.currentEvent?.startDate > this.now
        ? this.currentEvent?.startDate - this.now
        : this.currentEvent?.endDate - this.now;
    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    return `${minutes}m ${seconds}s`;
  }

  public get reward() {
    const { mosaicId, amount } = this.currentEvent?.rewards;
    const mosaicLabel =
      mosaicId === process.env.VUE_APP_ASSETS_ACTIV_IDENTIFIER
        ? "$ACTIV"
        : mosaicId;
    return `${amount} ${mosaicLabel}`;
  }

  public get progress() {
    const now = new Date().getTime();
    const { startDate, endDate } = this.currentEvent;
    const eventDuration = endDate - startDate;
    const timeleft = endDate - now;
    const progress = eventDuration - timeleft;
    const progressPercentage = (progress / eventDuration) * 100;
    return progressPercentage > 100 ? 100 : progressPercentage;
  }

  public get hasActivity() {
    return (
      this.statistics &&
      this.statistics.data &&
      this.statistics.data.totalEarned > 0
    );
  }

  public get userCompleted() {
    return (
      this.statistics &&
      this.statistics.activityAssets &&
      this.statistics.activityAssets.length > 0
    );
  }

  protected shareModal() {
    // display a custom modal popup
    // 3 cases: not started, not earned, earned
    if (this.eventStarted) {
      // not earned
      if (!this.userCompleted) {
        this.$root.$emit("modal", {
          type: "event",
          illustration: "event-trophy.png",
          title: this.currentEvent?.name,
          description: `Reward: ${this.reward}`,
          depiction: `https://storage.googleapis.com/dhealth_update/event_pictures/${this.currentEvent?.eventId}`,
          status: this.hasActivity ? "" : "Start now",
          progress: this.progress,
          remainTime: this.countDown,
          overlayColor: "rgba(0, 0, 0, .50)",
        });
        // earned
      } else {
        this.$root.$emit("modal", {
          type: "event",
          illustration: "event-trophy.png",
          title: "Congratulations!",
          description: `You have successfully completed the challenge.`,
          completedMessage: `${this.reward}`,
          progress: this.progress,
          overlayColor: "rgba(0, 0, 0, .50)",
        });
      }
      // not started
    } else {
      this.$root.$emit("modal", {
        type: "event",
        illustration: "event-trophy.png",
        title: "Event starts on:",
        description: moment
          .utc(this.currentEvent?.startDate)
          .format("dddd, MMM D"),
        overlayColor: "rgba(0, 0, 0, .50)",
      });
    }
  }

  protected async mounted() {
    await this.$store.dispatch("statistics/fetchEventStatistics", {
      eventId: this.currentEvent?.eventId,
      address: this.address,
    });
  }
}
