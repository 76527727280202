var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dapp-ui-popup"},[(_vm.config.overlayColor)?_c('div',{staticClass:"dapp-ui-popup__overlay",style:(`background-color: ${_vm.config.overlayColor}`),on:{"click":_vm.handleBgClick}}):_vm._e(),(_vm.config && _vm.config.type === 'form')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__form",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"dapp-ui-popup__modal__form__close",on:{"click":function($event){return _vm.$root.$emit('modal-close')}}},[_c('inline-svg',{attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":24}})],1),_c('h2',{staticClass:"dapp-ui-popup__modal__form__title"},[_vm._v(_vm._s(_vm.config.title))]),_vm._l((_vm.config.fields),function(field,index){return _c('div',{key:field.name + index,staticClass:"input-wrapper"},[_c('input',{staticClass:"dynamic-input",attrs:{"type":"text","placeholder":field.placeholder,"name":field.name},on:{"input":_vm.handleInput}})])}),_c('UiButton',{attrs:{"disabled":!_vm.isFormFilled},on:{"click":_vm.handleFormSubmission}},[_vm._v("Submit")])],2):_vm._e(),(_vm.config && _vm.config.type === 'notification')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__notification",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"dapp-ui-popup__modal__notification__close",on:{"click":function($event){return _vm.$root.$emit('modal-close')}}},[_c('inline-svg',{attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":32}})],1),(_vm.config.illustration)?_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.getImageUrl(_vm.config.illustration),"alt":_vm.config.title || _vm.config.description}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.config.description)}})]):_vm._e()]):_vm._e(),(_vm.config && _vm.config.type === 'share')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__share",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px`)},[_c('div',{staticClass:"flex flex-row justify-between items-center mb-[32px]"},[_c('div',{staticClass:"row-item"},[_c('h3',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$t('common.word_share'))}})]),_c('div',{staticClass:"row-item"},[_c('inline-svg',{staticClass:"dapp-ui-popup__modal__notification__close",attrs:{"src":_vm.getImageUrl('icons/close-icon.svg'),"width":32},on:{"click":function($event){return _vm.$root.$emit('modal-close')}}})],1)]),_c('div',{staticClass:"flex flex-row mb-[32px]"},_vm._l((_vm.socialPlatforms),function(item,index){return _c('div',{key:index + item.title,staticClass:"social-item"},[_c('a',{attrs:{"href":item.shareUrl,"target":"_blank","rel":"noopener"}},[_c('img',{attrs:{"src":_vm.getImageUrl(`${item.icon}`),"alt":item.title}}),_c('span',{staticClass:"title",domProps:{"innerHTML":_vm._s(item.title)}})])])}),0),_c('ReferralInput',{attrs:{"type":"link","placeholder":_vm.$t('dashboard.copy_referral_link'),"val":_vm.refCode}})],1):_vm._e(),(_vm.config && _vm.config.type === 'confirm')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__notification",style:(`background: ${
      _vm.config.modalBg ? _vm.config.modalBg : '#fff'
    };max-width: ${_vm.config.width}px;`)},[(_vm.config.illustration)?_c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":_vm.getImageUrl(_vm.config.illustration),"alt":_vm.config.title || _vm.config.description}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.config.description)}})]):_vm._e(),_c('div',{staticClass:"dapp-ui-popup__modal__notification__content"},[_c('p',[_vm._v(_vm._s(_vm.config.question))]),_c('div',[_c('UiButton',{style:(`margin-top: 10px;`),on:{"click":_vm.action}},[_vm._v(" Yes ")]),_c('UiButton',{style:(`margin-top: 10px;`),on:{"click":function($event){return _vm.$root.$emit('modal-close')}}},[_vm._v(" No ")])],1)])]):_vm._e(),(_vm.config && _vm.config.type === 'event')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__event"},[_c('img',{staticClass:"image",attrs:{"src":_vm.config.illustration ? _vm.getImageUrl(_vm.config.illustration) : ''}}),_c('div',{class:_vm.config.depiction
          ? 'event-content'
          : _vm.config.completedMessage
          ? 'event-content absolute-completed'
          : 'event-content absolute'},[(_vm.config.title)?_c('div',[_c('label',{class:_vm.config.completedMessage ? 'event-title-completed' : 'event-title'},[_vm._v(" "+_vm._s(_vm.config.title)+" ")])]):_vm._e(),(_vm.config.description)?_c('div',[_c('label',{class:_vm.config.completedMessage
              ? 'event-description-completed'
              : 'event-description'},[_vm._v(" "+_vm._s(_vm.config.description)+" ")])]):_vm._e(),(_vm.config.depiction)?_c('div',[_c('img',{staticClass:"event-depiction",attrs:{"src":_vm.config.depiction}})]):_vm._e(),(_vm.config.status)?_c('div',[_c('label',{staticClass:"event-status"},[_vm._v(_vm._s(_vm.config.status))])]):_vm._e(),(_vm.config.completedMessage)?_c('div',{staticClass:"completed-msg-div"},[_c('img',{staticClass:"activ-coin-img",attrs:{"src":_vm.getImageUrl('activ-coin.png')}}),_c('label',{staticClass:"completed-msg-label"},[_vm._v(_vm._s(_vm.config.completedMessage))])]):_vm._e(),(_vm.config.progress)?_c('div',{staticClass:"event-progress-div"},[_c('div',{class:_vm.config.completedMessage
              ? 'event-progress-completed'
              : 'event-progress'},[_c('div',{class:_vm.config.completedMessage ? 'event-bar-completed' : 'event-bar',style:(`width: ${_vm.config.progress}%`)})])]):_vm._e(),(_vm.config.remainTime)?_c('div',[_c('img',{staticClass:"event-timer",attrs:{"src":_vm.getImageUrl('event-timer.png')}}),_c('label',{staticClass:"event-timer-label"},[_vm._v(" "+_vm._s(`Event Ends in ${_vm.config.remainTime}`)+" ")])]):_vm._e()])]):_vm._e(),(_vm.config && _vm.config.type === 'promotion')?_c('div',{staticClass:"dapp-ui-popup__modal dapp-ui-popup__modal__promotion"},[_c('img',{staticClass:"image",attrs:{"src":_vm.config.illustration ? _vm.getImageUrl(_vm.config.illustration) : ''},on:{"click":function($event){return _vm.action()}}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }