/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */
// external dependencies
import { configureChains, createConfig, fetchBalance } from "@wagmi/core";
import { publicProvider } from "@wagmi/core/providers/public";
import { bsc, bscTestnet } from "viem/chains";

export class Web3Service {
  private static instance: Web3Service;

  private constructor() {
    const { publicClient, webSocketPublicClient } = configureChains(
      [bsc, bscTestnet],
      [publicProvider()]
    );

    createConfig({
      autoConnect: true,
      publicClient,
      webSocketPublicClient,
    });
  }

  public static getInstance(): Web3Service {
    if (!Web3Service.instance) {
      Web3Service.instance = new Web3Service();
    }
    return Web3Service.instance;
  }

  public async getUserActivBalance(address: string): Promise<number> {
    const balance = await fetchBalance({
      address: address as any,
      token: process.env.VUE_APP_ASSET_SMART_CONTRACT_ADDRESS as any,
    });
    return Number(balance.formatted);
  }
}
