/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2023-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */
// external dependencies
import { HttpRequestHandler } from "@/kernel";

// internal dependencies
import { BackendService } from "./BackendService";
import { EventDTO } from "@/models/EventDTO";

export class EventService extends BackendService {
  private static instance: EventService;
  private _handler: HttpRequestHandler;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    super();
    this._handler = new HttpRequestHandler();
  }

  protected get handler() {
    return this._handler;
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): EventService {
    if (!EventService.instance) {
      EventService.instance = new EventService();
    }

    return EventService.instance;
  }

  public async getEvent() {
    // fetch from backend runtime
    const response = await this.handler.call(
      this.getUrl(`events?isActive=true`),
      "GET",
      undefined, // no-body
      { withCredentials: true, credentials: "include" }
    );

    // responds with a singular configuration object
    return response.data.data[0] as EventDTO;
  }
}
