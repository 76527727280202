/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vue Frontend
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */
// external dependencies
import moment from "moment";

// internal dependencies
import { BackendService } from "./BackendService";
import { HttpRequestHandler } from "@/kernel/remote/HttpRequestHandler";
import { UserStatisticsDTO } from "@/models/UserStatisticsDTO";
import { EventStatisticsDTO } from "@/models/EventStatisticsDTO";

export class StatisticsService extends BackendService {
  private static instance: StatisticsService;

  private constructor() {
    super();
  }

  public static getInstance(): StatisticsService {
    if (!StatisticsService.instance) {
      StatisticsService.instance = new StatisticsService();
    }

    return StatisticsService.instance;
  }

  /**
   * This property sets the request handler used for the implemented
   * requests. This handler forwards the execution of the request to
   * `axios`.
   *
   * @access protected
   * @returns {HttpRequestHandler}
   */
  protected get handler(): HttpRequestHandler {
    return new HttpRequestHandler();
  }

  /**
   * This method implements fetching of quick stats
   *
   * @param params
   * @returns
   */
  public async getUserStatistics(
    address: string
  ): Promise<UserStatisticsDTO[]> {
    // configure statistics request
    const params = [
      `period=${moment(new Date()).utc().format("YYYYMMDD")}`,
      `periodFormat=D`,
    ];

    // execute request
    const response = await this.handler.call(
      this.getUrl(`statistics/users/${address}?${params.join("&")}`),
      "GET",
      undefined, // no-body
      {
        withCredentials: true,
        credentials: "include",
      },
      {} // no-headers
    );

    // returns an array of statistics entries
    // PaginatedResultDTO adds one more "data"
    return response.data.data as UserStatisticsDTO[];
  }

  public async getEventStatistics(
    eventId: string,
    address: string
  ): Promise<EventStatisticsDTO[]> {
    const params = [`eventId=${eventId}`];
    const response = await this.handler.call(
      this.getUrl(`statistics/events/${address}?${params.join("&")}`),
      "GET",
      undefined, // no-body
      {
        withCredentials: true,
        credentials: "include",
      },
      {} // no-headers
    );

    // returns an array of statistics entries
    // PaginatedResultDTO adds one more "data"
    return response.data.data as EventStatisticsDTO[];
  }

  public async getUserActivBalance(address: string): Promise<number> {
    // execute request
    const response = await this.handler.call(
      this.getNodeUrl(`accounts/${address}`),
      "GET",
      undefined, // no-body
      {} // no-headers
    );
    for (const mosaic of response.data?.account?.mosaics) {
      if (mosaic.id === process.env.VUE_APP_ASSETS_ACTIV_IDENTIFIER)
        return mosaic.amount;
    }
    return 0;
  }
}
