/**
 * This file is part of dHealth dApps Framework shared under LGPL-3.0
 * Copyright (C) 2022-present dHealth Network, All rights reserved.
 *
 * @package     dHealth dApps Framework
 * @subpackage  Vuex Store
 * @author      dHealth Network <devs@dhealth.foundation>
 * @license     LGPL-3.0
 */

// external dependencies
import { ActionContext } from "vuex";
import { bsc, bscTestnet } from "viem/chains";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/vue";
import { Web3Modal } from "@web3modal/wagmi/dist/types/src/client";

// internal dependencies
import { AwaitLock } from "../AwaitLock";
import { RootState } from "./Store";
import { Web3Service } from "@/services/Web3Service";

const Lock = AwaitLock.create();

export type Web3ModalType = Web3Modal | undefined;
export type ModalType = any;

export interface Web3ModuleState {
  initialized: boolean;
  web3Modal: Web3ModalType;
  modal: ModalType;
  activBalance: number;
}

export type Web3ModuleContext = ActionContext<Web3ModuleState, RootState>;

export const Web3Module = {
  // this store module is namespaced, meaning the
  // module name must be included when calling a
  // mutation, getter or action, i.e. "integrations/getIntegrations".
  namespaced: true,
  state: (): Web3ModuleState => ({
    initialized: false,
    web3Modal: undefined,
    modal: undefined,
    activBalance: 0,
  }),

  getters: {
    getWeb3Modal: (state: Web3ModuleState): Web3ModalType => state.web3Modal,
    getModal: (state: Web3ModuleState): ModalType => state.modal,
    getActivBalance: (state: Web3ModuleState): number => state.activBalance,
  },

  mutations: {
    /**
     *
     */
    setInitialized: (state: Web3ModuleState, payload: boolean): boolean =>
      (state.initialized = payload),

    setWeb3Modal: (
      state: Web3ModuleState,
      payload: Web3ModalType
    ): Web3ModalType => (state.web3Modal = payload),

    setModal: (state: Web3ModuleState, modal: ModalType): ModalType =>
      (state.modal = modal),

    setActivBalance: (state: Web3ModuleState, balance: number): number =>
      (state.activBalance = balance),
  },

  actions: {
    /**
     *
     */
    async initialize(context: Web3ModuleContext): Promise<boolean> {
      const callback = async () => {
        // initialization of this module is empty
        context.commit("setInitialized", true);
      };

      // acquire async lock until initialized
      await Lock.initialize(callback, context);
      return true;
    },

    async setupWeb3(context: Web3ModuleContext): Promise<void> {
      // 1. Get projectId
      const projectId = "52107b3ef66ae6eb850e8ce79e26d27e";

      // 2. Create wagmiConfig
      const metadata = {
        name: "Connect your Wallet",
        description: "Update wallet connection",
        url: "https://app.update.dhealth.com",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
      };

      // 3. Theme mode
      const themeMode = "light";

      const chains = [bsc, bscTestnet];
      const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

      // 3. Create modal
      const web3Modal = createWeb3Modal({
        wagmiConfig,
        projectId,
        chains,
        themeMode,
      });
      const modal = useWeb3Modal();

      context.commit("setWeb3Modal", web3Modal);
      context.commit("setModal", modal);
    },

    async fetchActivBalance(
      context: Web3ModuleContext,
      address: string
    ): Promise<number> {
      const service = Web3Service.getInstance();
      const activBalance = await service.getUserActivBalance(address);

      context.commit("setActivBalance", activBalance);
      return activBalance;
    },
  },
};
